import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import downtown from "../images/backrounds/downtown-sf.png";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="MSP"
        description="729 Solutions proudly provides MSP Services."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${downtown})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">MSP Partnership</h1>
            <p className="text-white">
              Providing development and design support to Managed Service
              Providers.
            </p>
            <CTA
              href="/contact-us/"
              text="Contact us today"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                MSP customers need a lot of love. They are asking for new
                technology, modern websites and mobile applications every day.
              </h2>
              <p>
                We know the most important thing to you is your reputation and
                making sure every job is executed perfectly so your customer is
                happy. But when you can’t offer all the endless technical
                services and resources your customers want, you need a quality
                partner to deliver.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row my-5">
            <div className="col-12 col-lg-6">
              <h3>We can help your customers with any of the following:</h3>
              <ul className="text-primary mt-5">
                <li className="mb-3">
                  <span className="text-dark">
                    App builds – mobile and responsive web
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Moving to the Cloud – AWS, Azure, etc.
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Website Design and Development, WordPress, Drupal,
                    Ecommerce, and custom
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Systems Integrations – SAAS tools: CRMs, Customer Service,
                    Telephony, APIs, etc.
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <h3>We Guarantee To:</h3>
              <ul className="text-primary mt-5">
                <li className="mb-3">
                  <span className="text-dark">
                    Set up a scoping call within three days
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Provide an estimate within a week
                  </span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">Start work within two weeks</span>
                </li>
                <li className="mb-3">
                  <span className="text-dark">
                    Offer competitive pricing and do exceptional work
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-12">
              <p>
                We pay a <b>15% referral fee</b> and will give you or your
                customer a free scoping consultation with project estimates.
                Refer us or subcontract as you please. Trust in 729’s 15 years
                of success and experience as a well-vetted software development
                and DevOps shop.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <CTA
                href="/contact-us/"
                text="See More Of What We Do"
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="my-6 py-6"
          style={{
            backgroundImage: `url(${patternHex})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h2>Contact Us Today</h2>
                <p>
                  Please click the button below to fill out the form. We will
                  contact you as soon as possible to schedule a call.
                </p>
                <p>
                  You can also send an email to{" "}
                  <a href="mailto:graham@729.io?subject=Managed Service Provider">
                    graham@729.io
                  </a>{" "}
                  to schedule a time to talk about how best to work together.
                </p>
              </div>
              <div className="col-12 my-5">
                <CTA
                  href="/contact-us/"
                  text="Make A Time To Talk"
                  className="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};

export default Index;
